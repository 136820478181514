@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&family=Protest+Guerrilla&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.carousel-container {
  overflow: visible; /* Ensure the carousel container allows overflow */
}

.carousel-item {
  padding-bottom: 16px; /* Increase bottom padding to accommodate shadow */
}

.react-elastic-carousel-wrapper {
  overflow: visible !important; /* Override internal carousel overflow */
}

@media (max-width: 768px) {
  .rec-arrow {
    display: none;
  }
  
}
@media (min-width:768px) {
  .tab-border {
    position: relative; 
  }
  
  .tab-border::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px; 
    width: 100%;
    height: 4px; 
    background-color: currentColor; 
  }
  
  .tab-border::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 4px;
    background-color: #D2AD20; 
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out; 
  }
  
  .tab-border.animate-border::after {
    transform: scaleX(1); 
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(100);
    opacity: 1;
  }
}

@keyframes slide-out-left {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slide-out-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}





.slide-in-left {
  animation: slide-in-left 0.5s ease-in-out forwards;
}

.slide-in-right {
  animation: slide-in-right 0.5s ease-in-out forwards;
}

.slide-out-left {
  animation: slide-out-left 0.5s ease-in-out forwards;
}

.slide-out-right {
  animation: slide-out-right 0.5s ease-in-out forwards;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(200%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation: slideInFromLeft 0.4s ease-out forwards;
}

input[name="name"]:focus ~ .name {
  @apply bg-white text-green-500 transform -translate-y-7 -translate-x-4 scale-75;
}


.custom-shadow {
  @apply shadow-none; 
  box-shadow: 0px 4px 0px #fff;
}

.card-shadow{
  @apply shadow-none;
  box-shadow: 4px 4px 4px gray;
}

.dashboard-shadow{
  @apply shadow-none; 
  box-shadow: 4px 4px 4px  gray;
 
}


.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: inline-block;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}




.no-scrollbar::-webkit-scrollbar {
  display: none; 
}

.no-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.carousel-container {
  display: flex;
  justify-content: flex-start; /* Aligns the carousel container to the left */
}

.rec-slider-container {
  justify-content: flex-start; /* Ensures items are aligned to the left */
}

.carousel-item {
  display: flex;
  justify-content: flex-start; /* Aligns the content inside each carousel item to the left */
}

.rec-item-wrapper {
  margin-right: 0; /* Removes any right margin if present */
}

