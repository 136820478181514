/* This targets all pagination dots */
.rec.rec-pagination .rec-dot {
    background-color: #ddd !important; /* Change default color */
    box-shadow: none !important;
  }
  
  /* This targets the active pagination dot */
  .rec.rec-pagination .rec-dot_active {
    background-color: #D2AD20 !important; /* Change active color */
  }
  